import React, { useEffect, useState } from "react";
import { Grid, Button, IconButton, Tooltip, LinearProgress } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../styles/components/product_items.css";
import { useAppContext } from "./Auth";

function Product_items({
  products,
  isDonationEdited,
  setIsDonationEdited,
  updateDonationAmount,
  countersReset,
}) {
  const [counters, setCounters] = useState(products.map(() => 0));
  const [showDecrement, setShowDecrement] = useState(products.map(() => false));
  const { selectedProducts, setSelectedProducts } = useAppContext();

  useEffect(() => {
    if (countersReset) {
      setCounters(products.map(() => 0));
      setShowDecrement(products.map(() => false));
      setSelectedProducts([]);
    }
  }, [countersReset, products, setSelectedProducts]);

  useEffect(() => {
    if (!isDonationEdited) {
      updateDonationAmount(calculateTotalPrice());
    } else if (counters.every((item) => item === 0)) {
      setIsDonationEdited(false);
    }
  }, [counters, products, isDonationEdited, setIsDonationEdited, updateDonationAmount]);

  const incrementCounter = (index) => {
    setCounters((prevCounters) => {
      const newCounters = [...prevCounters];
      newCounters[index] += 1;
      return newCounters;
    });

    setShowDecrement((prevShowDecrement) => {
      const newShowDecrement = [...prevShowDecrement];
      newShowDecrement[index] = true;
      return newShowDecrement;
    });

    const selectedProductIndex = selectedProducts.findIndex(
      (item) => item.donation_product === products[index].product_item.id
    );

    if (selectedProductIndex !== -1) {
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = [...prevSelectedProducts];
        newSelectedProducts[selectedProductIndex].quantity += 1;
        return newSelectedProducts;
      });
    } else {
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        {
          donation_product: products[index].product_item.id,
          quantity: 1,
        },
      ]);
    }
  };

  const decrementCounter = (index) => {
    setCounters((prevCounters) => {
      const newCounters = [...prevCounters];
      if (newCounters[index] > 0) {
        newCounters[index] -= 1;
      }
      setShowDecrement((prevShowDecrement) => {
        const newShowDecrement = [...prevShowDecrement];
        newShowDecrement[index] = newCounters[index] > 0;
        return newShowDecrement;
      });
      return newCounters;
    });

    const selectedProductIndex = selectedProducts.findIndex(
      (item) => item.donation_product === products[index].product_item.id
    );

    if (selectedProductIndex !== -1) {
      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = [...prevSelectedProducts];
        if (newSelectedProducts[selectedProductIndex].quantity > 0) {
          newSelectedProducts[selectedProductIndex].quantity -= 1;
        }
        if (newSelectedProducts[selectedProductIndex].quantity === 0) {
          newSelectedProducts.splice(selectedProductIndex, 1);
        }
        return newSelectedProducts;
      });
    }
  };

  const calculateTotalPrice = () => {
    return products.reduce((total, product, index) => {
      return total + product.product_item.price * counters[index];
    }, 0);
  };

  return (
    <div className="product_item">
      <Grid container spacing={2}>
        {products.length === 0 ? (
          <Grid item xs={12}>
            <div className="no-products-message">
              <h6>There are no products</h6>
            </div>
          </Grid>
        ) : (
          products.map((product, index) => {
            const progressPercentage = product.target_quantity
              ? (product.current_quantity / product.target_quantity) * 100
              : 0;

            return (
              <Grid item xs={12} sm={6} md={6} lg={4} key={product.id} className="product_item_main">
                <div className="product_item_bg">
                  <div className="product_item_bg_main_content">
                    {/* Product Name */}
                    <h3>{product.product_item.name}</h3>

                    {/* Product Image */}
                    <div className="product_item_bg_main_image">
                      {product.product_item?.image && (
                        <img
                          src={`${product.product_item.image.base_url}${product.product_item.image.image_path}`}
                          alt={product.product_item.name}
                        />
                      )}
                    </div>

                    {/* Tooltip for Additional Product Information */}
                    <Tooltip title={product.product_item.description} placement="top">
                      <span className="know-more-text">Know More</span>
                      <IconButton className="info-icon-button">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                    {/* Product Price and Quantity Controls */}
                    <div className="product_product_item_price">
                      <h4>
                        <span>₹</span>
                        {Intl.NumberFormat("en-IN").format(product.product_item.price)}
                      </h4>
                      <div className="ganga_jal_cost_button">
                        {showDecrement[index] && (
                          <Button
                            className="minus_btn"
                            onClick={() => decrementCounter(index)}
                          >
                            -
                          </Button>
                        )}
                        <span onClick={() => incrementCounter(index)}>
                          {counters[index] === 0 ? "Add" : counters[index]}
                        </span>
                        <Button
                          className="plus_btn"
                          onClick={() => incrementCounter(index)}
                        >
                          +
                        </Button>
                      </div>
                    </div>

                    {/* Quantity Progress Bar */}
                    <div className="quantity-progress">
                      <LinearProgress variant="determinate" value={progressPercentage} />
                      <p>{`${product.current_quantity || 0} of ${product.target_quantity || 0} items funded`}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
    </div>
  );
}

export default Product_items;
