import React from "react";
import Header from "../layouts/Header";
import { Container } from "@mui/material";
import Footer from '../layouts/Footer';
import whatsapp from '../assets/images/icons/whatsapp.png'


function Cancellation_policy_VDS() {
    return (
        <>
            <Header />
            <section className="terms_conditions">
                <Container maxWidth='xl'>
                    <div className="terms_conditions_title">
                        <h2>Cancellation Policy</h2>
                     <h3>At Vaidika Dharma Services Pvt. LTD.</h3>
                    </div>
                </Container>
            </section>
            <section className="terms_conditions_content_main">
                <Container maxWidth='xl'>
                    <div className="terms_conditions_content terms_conditions_content_2">
                    <h3>Pricing Policy</h3>
                    <p>For Donation based fundraiser the pricing depends on the need of the campaigner and ranges between Rs. 1 to Rs. 100000. A tip amount which is optional might be collected from the donors.</p>
                    <p>For Pujas, the pricing is as per the market standards. A nominal service charge will be collected along with taxes and the Puja ticket cost. This might include Prasad delivery charges and other handling charges as per the nature of the campaign.</p>
                    </div>
                    <div className="terms_conditions_mini_content">
                        <h3>For Donations</h3>
                        <p><span>All Donations made are Non refundable.</span></p>
                        <p>We retain the right to make changes to, temporarily or permanently suspend the donation facility, with or without prior notice. In such cases, we shall not be held liable to the Donor or any third party for such modifications or discontinuation. Donations made will be allocated to the directed User, and no substitution of donations or campaigns is permitted.</p>
                        <p>If, for valid reasons, we suspect that the Campaigner has not adhered to the Donor's intentions without prior communication to the Donor and us, we retain the sole discretion to refund the Donation to the Donor. Furthermore, we may choose to withhold any additional disbursement of the Donation, and the Campaigner is expected to promptly comply with such directives.</p>
                    </div>
                    <div className="terms_conditions_mini_content">
                        <h3>For Pujas/Homam</h3>
                        <p>If, for any reason, you are dissatisfied with a booking, we encourage you to familiarize yourself with our Cancellation and Refund Policy. The following terms apply to any religious sevas you have booked with us.</p>

                        <p><span>Your Order Cancellation Rights</span> :  Generally, we do not entertain cancellations. However, if an order was mistakenly booked or duplicated due to technical issues from our payment gateway partner, you may contact us for an order cancellation request via email at <a href="mailto:support@vedasankalpa.com">support@vedasankalpa.com</a> . Such requests should be submitted within 3 days of order booking. Please note that we reserve the right to decline cancellation requests that do not meet the specified conditions at our sole discretion.</p>

                        <p><span>Conditions for Refund</span> : Sevas(Puja/Homam) that have been booked and performed are not eligible for refunds. For sevas booked but not yet performed, cancellations can only be made under the stipulated cancellation terms.</p>
                        <p><strong>Note :</strong> <span className="services">vedasankalpa.com</span> operates under Vaidika Dharma Services Private Limited, will be receiving Invoices and bill in the name of Vaidika Dharma Services Private Limited</p>
                    </div>
                </Container>
            </section>
            <Footer />
            <a href="https://api.whatsapp.com/send?phone=919256585656" target="_blank"  class="btn-whatsapp-pulse btn-whatsapp-pulse-border">
            <img alt="whatsapp" src={whatsapp}/>
            </a>
        </>
    )
}
export default Cancellation_policy_VDS;