import axios from "axios";
const vedatoken = localStorage.getItem("vedatoken");
//let api = "http://localhost:8000/v1/";
//let api = "https://prod.backend.vedasankalpa.com/v1/";
let api = "https://backend.vedasankalpa.com/v1/";
const createAxiosInstance = (vedatoken) => {
  return axios.create({
    baseURL: `${api}/`,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${vedatoken}`,
    },
  });
};
export const instance1 = createAxiosInstance(vedatoken);
export const updateToken = (newToken) => {
  instance1.defaults.headers["Authorization"] = `Bearer ${newToken}`;
};

export const instance = createAxiosInstance(vedatoken);

export const noauthinstance = axios.create({
  baseURL: `${api}/`,
  headers: {
    Accept: "application/json",
  },
});

export default instance1;